import { PictureOutlined } from "@ant-design/icons";
import { BarCustomLayerProps, BarLayer, BarTooltipProps, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Row } from "antd";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { getBarChartTheme } from "./styling";
import { FilterState } from "../Reports";
import { IChartData } from "../../../selectors/ChartData";
import { joinArrayStringItems } from "../../../utils/array";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";

dayjs.extend(customParseFormat);


type BarChartData = {
    barChartData: BarChartDatum[];
    barChartKeys: string[];
}

type BarChartDataProps = SectionDescriptionParts & {
    containerId: string;
    data: IChartData[];
}

type BarChartDatum = TopDescription & BottomDescription & BarChartDatumPartial & {
    containerId: string;
    grouping: string;
}

type BarChartDatumPartial = {
    [key: string]: number | string;
}

type BottomDescription = {
    filterDescription: string;
    explanation: string;
}

type ImageDescriptionProps = BarChartData & TopDescription & {
    filterState: FilterState;
}

type SectionDescriptionParts = {
    topDescription: TopDescription;
    bottomDescription: BottomDescription;
}

type TargetProgressBarChartProps = {
    category: string;
    data: IChartData[];
    filterState: FilterState;
    numberOfDatasets: number;
}

type TopDescription = {
    categoryPrefix: string;
    mainDescription: string;
    dateDescription: string;
    datasetsDescription: string;
}


const DATE_FORMAT_CHART = "MMM YY";
const DATE_FORMAT_TEXT = "MMMM YYYY";
const HEIGHT_PER_TEXT_LINE = 20;
const IMAGE_FORMATS = [ImageFormat.PNG, ImageFormat.JPEG];
const WIDTH_PER_CHAR = 9;


const isDateSelectionSingleMonth = (start: Dayjs, end: Dayjs): boolean => start.month() === end.month();

const getDateDescription = (begin: Dayjs, end: Dayjs): string => {
    const isSingleMonth = isDateSelectionSingleMonth(begin, end);
    return isSingleMonth ? begin.format(DATE_FORMAT_TEXT) 
        : `${begin.format(DATE_FORMAT_TEXT)} - ${end.format(DATE_FORMAT_TEXT)}`;
};

const getShortFilterDescription = (filterState: FilterState): string => {
    const { divisions, departments, miscTags, programs } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    const filterStringBase = filters.length > 0 ? filters[0] : "the whole BBC";
    const filterString = filters.length > 1 ? `${filterStringBase} and ${filters.length - 1} other(s)` : filterStringBase;

    return filterString;
};

const getFilterDescription = (filterState: FilterState): string => {
    const { categories, contentTypes, departments, divisions, miscTags, platforms, programs, teams } = filterState;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...categories.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...contentTypes.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...platforms.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...teams.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    return filters.length === 0 ? "Datasets: BBC Overall" : `Datasets: ${joinArrayStringItems(filters)}.`;
};

const getSectionDescriptionParts = (
    category: string, 
    filterState: FilterState, 
    numberOfDatasets: number
): SectionDescriptionParts => ({
    topDescription: {
        categoryPrefix: category,
        mainDescription: "Proportion of datasets on target compared with first entry",
        dateDescription: getDateDescription(filterState.range.begin, filterState.range.end),
        datasetsDescription: `${numberOfDatasets} datasets who published`,
    },
    bottomDescription: {
        filterDescription: getFilterDescription(filterState),
        explanation: "'First Entry' combines all datasets' respective first months"
    },
});

const mapProgressChartId = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "exceeds":
        return "Exceeds";
    case "lt5":
        return "Within 5%";
    case "lt10":
        return "Within 10%";
    case "gt10":
        return "More than 10%";
    default:
        return "";
    }
};

const mapDateGroupingString = (dateGrouping: string): string => dayjs(dateGrouping).isValid() 
    ? dayjs(dateGrouping).format(DATE_FORMAT_CHART) : dateGrouping;

const getBarChartData = (props: BarChartDataProps): BarChartData => {
    const { containerId, data, topDescription, bottomDescription } = props;

    const barChartKeys = Array.from(new Set<string>(data.map(({ targetState }) => mapProgressChartId(targetState))));
    const groupings = Array.from(new Set<string>(data.map(({ groupedDate }) => mapDateGroupingString(groupedDate) )));

    const barChartData: BarChartDatum[] = groupings.map(grouping => {
        const groupingData = data.filter(({ groupedDate }) => grouping === mapDateGroupingString(groupedDate));
        const totalValue = groupingData.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
        const values = Object.fromEntries(groupingData.map(({ targetState, value }) => (
            [mapProgressChartId(targetState), ((value / totalValue) * 100)]
        )));

        return { grouping, ...values, containerId, ...topDescription, ...bottomDescription };
    }).sort((a) => dayjs(a.grouping).isValid() ? -1 : 1);

    return { barChartData, barChartKeys };
};

const getProgressChartColor = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "Exceeds":
        return "#477302";
    case "Within 5%":
        return "#D3F8DC";
    case "Within 10%":
        return "#DE6363";
    case "More than 10%":
        return "#850002";
    default:
        return "#000000";
    }
};

const getBarChartLayers = (): BarLayer<BarChartDatum>[] => (
    ["grid", "axes", "bars", "totals", "markers", "legends", "annotations", BarChartTopDescription, BarChartBottomDescription]
);

const getProgressChartTextColor = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "Exceeds":
        return "#FFFFFF";
    case "Within 5%":
        return "#000000";
    case "Within 10%":
        return "#FFFFFF";
    case "More than 10%":
        return "#FFFFFF";
    default:
        return "#FFFFFF";
    }
};

const splitTextByMaxLength = (text: string, maxLength: number): string[] => {
    const words = text.split(" ");

    const lines: string[] = [];
    let currentString = "";
    for (const word of words) {
        if (currentString.length + 1 + word.length > maxLength) {
            lines.push(currentString);
            currentString = word;
        } else {
            currentString += " " + word;
        }
    }
    lines.push(currentString);

    return lines;
};

const getImageDescription = (
    { 
        barChartData, 
        barChartKeys, 
        categoryPrefix, 
        datasetsDescription, 
        dateDescription, 
        mainDescription, 
        filterState
    }: ImageDescriptionProps
): string => {
    const shortFilterDescription = getShortFilterDescription(filterState);

    const dataContext = `A horizontal bar chart showing the ${mainDescription.toLowerCase()}. The "First Entry" bar combines all datasets' respective first months`;
    const chartContext = `The chart includes data from ${datasetsDescription.toLowerCase()} ${categoryPrefix} data for ${dateDescription}, and applies to ${shortFilterDescription}`;

    const comparisonDescriptions: string[] = [];
    barChartKeys.slice(0, 2).map(key => {
        const current = barChartData[0];
        const first = barChartData[1];

        if (current && current[key] && first && first[key]) {
            const conjunction = key === "Exceeds" ? "met or exceeded" : "were within 5% of";
            const dateDescription = dayjs(current.grouping, DATE_FORMAT_CHART).format(DATE_FORMAT_TEXT);
            comparisonDescriptions.push(`In ${dateDescription}, ${Math.round(current[key] as number)}% of datasets ${conjunction} their target compared to ${Math.round(first[key] as number)}% in their first entry`);
        }
    });
    const chartDescription = comparisonDescriptions.join(". ");

    return `${dataContext}. ${chartContext}. ${chartDescription}.`;
};

const getBarAriaLabel = (datum: ComputedDatum<BarChartDatum>): string => {
    const dateString = dayjs(datum.data.grouping).isValid() 
        ? dayjs(datum.data.grouping, DATE_FORMAT_CHART).format(DATE_FORMAT_TEXT) : datum.data.grouping;
    return `${dateString}, ${datum.id}, ${datum.formattedValue}%`;
};


const BarChartTopDescription = ({ bars, width } : BarCustomLayerProps<BarChartDatum>): JSX.Element | null => {
    if (bars.length > 0) {
        const maxLength = width / WIDTH_PER_CHAR;
        const { categoryPrefix, mainDescription, dateDescription, datasetsDescription } = bars[0].data.data;
        const mainDescriptionLines = splitTextByMaxLength(`${categoryPrefix}: ${mainDescription}`, maxLength);
        const dateDescriptionLines = splitTextByMaxLength(dateDescription, maxLength);
        const datasetsDescriptionLines = splitTextByMaxLength(datasetsDescription, maxLength);
        const lines = [...mainDescriptionLines, ...dateDescriptionLines, ...datasetsDescriptionLines];
    
        return (
            <>
                {
                    lines.map((line, index, array) => (
                        <text
                            key={uuidv4()}
                            className={`${bars[0].data.data.containerId} svg-chart-top-text`}
                            x={0}
                            y={(HEIGHT_PER_TEXT_LINE * -2) - ((array.length - index) * HEIGHT_PER_TEXT_LINE)}
                            style={{ fontFamily: "sans-serif", fontSize: 16 }}
                        >
                            {line}
                        </text>
                    ))
                }
            </>
        );    
    } else {
        return null;
    }
};

const BarChartBottomDescription = ({ bars, innerHeight, width } : BarCustomLayerProps<BarChartDatum>): JSX.Element | null => {
    if (bars.length > 0) {
        const maxLength = width / WIDTH_PER_CHAR;
        const { filterDescription, explanation } = bars[0].data.data;
        const filterDescriptionLines = splitTextByMaxLength(filterDescription, maxLength);
        const explanationLines = splitTextByMaxLength(explanation, maxLength);
        const lines = filterDescriptionLines.concat(explanationLines);
    
        return (
            <>
                {
                    lines.map((line, index) => (
                        <text
                            key={uuidv4()}
                            className={`${bars[0].data.data.containerId} svg-chart-bottom-text`}
                            x={0}
                            y={innerHeight + (HEIGHT_PER_TEXT_LINE * 2) + ((index + 1) * HEIGHT_PER_TEXT_LINE)}
                            style={{ fill: "#757575", fontFamily: "sans-serif", fontSize: 16 }}
                        >
                            {line}
                        </text>
                    ))
                }
            </>
        );    
    } else {
        return null;
    }
};

const BarChartTooltip = ({ id, value, color }: BarTooltipProps<BarChartDatum>): JSX.Element => (
    <BasicTooltip
        id={id}
        value={`${Math.round(value)}%`}
        enableChip={true}
        color={color}
    />
);


export const TargetProgressBarChart = ({ category, data, filterState, numberOfDatasets }: TargetProgressBarChartProps) => {
    const id = `${category}-target-progress-bar-chart`;
    const containerId = `${id}-container`;

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const { topDescription, bottomDescription } = getSectionDescriptionParts(category, filterState, numberOfDatasets);
    const { categoryPrefix, mainDescription, dateDescription, datasetsDescription } = topDescription;
    const { filterDescription, explanation } = bottomDescription;
    
    const { barChartData, barChartKeys } = getBarChartData({ containerId, data, topDescription, bottomDescription });
    
    const theme = getBarChartTheme();
    const imageDescription = getImageDescription({ barChartData, barChartKeys, filterState, ...topDescription });

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    return (
        <Row align="top" id={id} gutter={[0, 32]}>
            <Col span={21} style={{ marginBottom: 16 }}>
                <p aria-hidden style={{ marginBottom: 0 }}><b>{categoryPrefix}</b>: {mainDescription}</p>
                <p aria-hidden style={{ marginBottom: 0 }}>{dateDescription}</p>
                <p aria-hidden style={{ marginBottom: 0 }}>{datasetsDescription}</p>
            </Col>
            <Col span={3}>
                {
                    IMAGE_FORMATS.map(format => (
                        <Button
                            key={`download-${id}-${format}`}
                            disabled={imageLoading}
                            icon={<PictureOutlined />}
                            loading={imageLoading}
                            onClick={async () => await onClickDownloadImage(format)}
                            size="small"
                            style={{ marginBottom: 10, width: "100%" }}
                        >
                            {`.${format}`}
                        </Button>
                    ))
                }
            </Col>
            <Col span={24}>
                <Row>
                    <div id={containerId} style={{ height: 150, width: "100%" }}>
                        <ResponsiveBar
                            // Base
                            data={barChartData}
                            indexBy="grouping"
                            keys={barChartKeys}
                            groupMode="stacked"
                            layout="horizontal"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            minValue={0}
                            maxValue={100}
                            valueFormat=">-.3r"
                            padding={0.25}
                            margin={{ top: 35, bottom: 30, left: 75, right: 15 }}    
                            // Style
                            theme={theme}
                            colors={(datum) => getProgressChartColor(datum.id.toString())}
                            colorBy="id"
                            borderColor={{ from: "color", modifiers: [["darker", 1]] }}
                            borderWidth={1}
                            // Customisation
                            layers={getBarChartLayers()}
                            // Labels
                            enableLabel
                            label={(datum) => `${Math.round(datum.value || 0)}%`}
                            labelSkipWidth={1}
                            labelTextColor={(datum) => getProgressChartTextColor(datum.data.id.toString())}
                            // Grid & Axes
                            enableGridY={false}
                            enableGridX={true}
                            gridXValues={[0, 20, 40, 60, 80, 100]}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickValues: [0, 20, 40, 60, 80, 100],
                            }}
                            // Interactivity
                            tooltip={BarChartTooltip}
                            // Legends
                            legends={[{
                                anchor: "top-left",
                                dataFrom: "keys",
                                direction: "row",
                                justify: false,
                                itemDirection: "left-to-right",
                                itemHeight: 20,
                                itemsSpacing: 0,
                                itemWidth: 110,
                                symbolSize: 15,
                                translateX: 5,
                                translateY: -30,
                            }]}
                            // Accessibility
                            isFocusable
                            role="img"
                            ariaLabel={imageDescription}
                            barAriaLabel={getBarAriaLabel}
                        />
                    </div>
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 16 }}>
                <p aria-hidden style={{ color: "#757575", marginBottom: 0 }}>{filterDescription}</p>
                <p aria-hidden style={{ color: "#757575", marginBottom: 0 }}><em>{explanation}</em></p>
            </Col>
        </Row>
    );
};